import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smtp-validate',
  templateUrl: './smtp-validate.component.html',
  styleUrls: ['./smtp-validate.component.scss']
})
export class SmtpValidateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
