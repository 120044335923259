export class AppHandler {
  languageId: any = '14';
  rtl: any = '0';
  currencyId: any = '1';
  currencyValue: any = 'USD';
  latitude: number = 0.000000;
  longitude: number = 0.000000;
  address: string = '';
  selfPickup: number = 0;
}

